(function () {
    angular.module('informaApp')
        .service('WindowExtension', function () {
            return {
                setup: function () {
                    window.getScrollSize = getScrollBarWidth;
                }
            }
        });

    function getScrollBarWidth() {
        const outerDivStyle = {visibility: 'hidden', width: 100, overflow: 'scroll'};
        const outerDiv = $('<div>').css(outerDivStyle).appendTo('body');

        const widthWithScroll = $('<div>').css({width: '100%'}).appendTo(outerDiv).outerWidth();

        outerDiv.remove();

        return outerDivStyle.width - widthWithScroll;
    }
})();